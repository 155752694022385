// External packages
import * as React from "react"
import {
  Grid,
  Box,
  GridProps,
  BoxProps,
  Paragraph,
  Heading,
  Flex,
  Text,
  Input,
} from "theme-ui"
import GatsbyLink from "gatsby-link"
import { PageProps } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Shopstory } from "@shopstory/core/react"
import { navigate } from "gatsby"
import * as Accordion from "@radix-ui/react-accordion"
import * as RadioGroup from "@radix-ui/react-radio-group"
import * as Checkbox from "@radix-ui/react-checkbox"
import * as Slider from "@radix-ui/react-slider"
import { useLocation } from "@reach/router"
import { capitalize, kebabCase } from "lodash"
import Lottie from "react-lottie"

// Contexts
import { TeklaShopstoryProvider } from "../../../src/shopstory/Provider"
import { useStore } from "../../context/NewStoreContext"
import { useAccountContext } from "../../context/AccountContext"

// Utilities
import { isPurchasable } from "../../../src/components/v2/utils/product/isPurchasable"
import { formatMoneyAmount } from "../../utils/prices"
import { getTypePlural } from "../../utils/getTypePlural"

// Hooks
import useWindowSize from "../../hooks/useWindowSize"
import useLockedBody from "../../hooks/v2/useLockedBody"
import useLockedBodyMobile from "../../hooks/v2/useLockedBodyMobile"
import usePagination from "../../hooks/v2/usePagination"
import { useProductsPriceRange } from "../../hooks/v2/useProductsPriceRange"
import { useProductsBestsellers } from "../../hooks/v2/useProductsBestsellers"

// Components
import { CategoryPageProduct } from "gatsby-node"
import Layout from "../../components/layouts/layout"
import { Link, LinkProps } from "../../components/v2/Link"
import { Button } from "../../components/v2/Button"
import { Picture } from "../../components/v2/Picture"
import { UiProgress } from "../../components/v2/ui/Progress"
import { Icon } from "../../components/v2/ui/Icon"
import { Drawer } from "../../components/v2/ui/Drawer"
import {
  UiAccordionContent,
  UiAccordionHeader,
  UiAccordionItem,
  UiAccordionTrigger,
} from "../../components/v2/ui/Accordion"
import {
  UiRadioGroupField,
  UiRadioGroupItem,
  UiRadioGroupLabel,
} from "../../components/v2/ui/RadioGroup"
import {
  UiRadixCheckbox,
  UiRadixCheckboxIcon,
  UiRadixCheckboxLabel,
} from "../../components/v2/ui/RadixCheckbox"
import {
  UiSlider,
  UiSliderRange,
  UiSliderThumb,
  UiSliderTrack,
} from "../../components/v2/ui/Slider"
import SEO from "../../components/seo"
import { getBunnyImageSrc } from "../../../src/utils/bunny-cdn"

interface AllProductsTemplateProps {
  products: CategoryPageProduct[]
  seo: {
    canonicalUrl: string
    description: string
    metaImageUrl: string
    breadcrumbsList: {
      "@type": string
      position: number
      name: string
      item: string
    }[]
  }
}

type Data =
  | {
      variant: "product"
      priceMin: number | undefined
      priceMax: number | undefined
    } & CategoryPageProduct

interface Filter {
  color: {
    value: string
    label: string
  }[]
  fabric: {
    value: string
    label: string
  }[]
  pattern: {
    value: string
    label: string
  }[]
  price?: {
    min: number
    max: number
  }
  category: {
    value: string
    label: string
  }[]
  product: {
    value: string
    label: string
  }[]
}

interface SelectedFilters {
  category: string[]
  product: string[]
  color: string[]
  fabric: string[]
  pattern: string[]
  price_min: string
  price_max: string
}

type Product = CategoryPageProduct & {
  priceMin: number
  priceMax: number
}

const getFilterParam = (key: string, searchParams: URLSearchParams) => {
  const param = searchParams.get(key)
  if (param) {
    return param.split(",")
  }
  return []
}

// Filter products
export const filterProducts = (
  products: Product[],
  selectedFilters: SelectedFilters
) => {
  let filteredProducts = products
  if (
    !selectedFilters.price_min &&
    !selectedFilters.price_max &&
    !Object.values(selectedFilters).some((filter) => Boolean(filter?.length))
  ) {
    return products
  }

  // Filter by color
  if (selectedFilters?.color?.length > 0) {
    filteredProducts = filteredProducts?.filter((product) => {
      return product.colors?.some((color) =>
        selectedFilters.color.includes(color.value)
      )
    })
  }

  // Filter by fabric
  if (selectedFilters.fabric?.length > 0) {
    // If the selected fabric is flannel, include flannel and flannel-loungewear
    if (selectedFilters.fabric.includes("flannel")) {
      filteredProducts = filteredProducts?.filter((product) => {
        return (
          product.fabric?.value === "flannel" ||
          product.fabric?.value === "flannel-loungewear"
        )
      })
    } else {
      filteredProducts = filteredProducts?.filter((product) => {
        return selectedFilters.fabric.includes(product.fabric.value)
      })
    }
  }

  // Filter by pattern
  if (selectedFilters.pattern?.length > 0) {
    filteredProducts = filteredProducts?.filter((product) => {
      return selectedFilters.pattern.includes(product.pattern.value)
    })
  }

  // Filter by category
  if (selectedFilters.category?.length > 0) {
    filteredProducts = filteredProducts?.filter((product) => {
      return selectedFilters.category.includes(product.parent_category?.value)
    })
  }

  // Filter by product
  if (selectedFilters.product?.length > 0) {
    filteredProducts = filteredProducts?.filter((product) => {
      const productType = kebabCase(product.type?.originalLabel)
      return selectedFilters.product.includes(productType)
    })
  }

  // Filter by price
  if (selectedFilters.price_min && selectedFilters.price_max) {
    filteredProducts = filteredProducts?.filter((product) => {
      const priceMin = product.priceMin / 100
      const priceMax = product.priceMax / 100
      const selectedMin = parseInt(selectedFilters.price_min)
      const selectedMax = parseInt(selectedFilters.price_max)

      return (
        (priceMin >= selectedMin || priceMax >= selectedMin) &&
        (priceMin <= selectedMax || priceMax <= selectedMax)
      )
    })
  }

  return filteredProducts
}

// Get filter data from products
const getFilterData = (products: Product[], currentProducts = []) => {
  let filter: Filter = {
    category: [],
    product: currentProducts,
    color: [],
    fabric: [],
    pattern: [],
  }

  if (!products?.length) {
    return filter
  }

  // If it is parent category, include price filter
  if (products?.[0].priceMin) {
    const minPrice = Math.min(...products.map((item) => item.priceMin))
    const maxPrice = Math.max(...products.map((item) => item.priceMax))

    filter = {
      ...filter,
      price: {
        min: minPrice / 100,
        max: maxPrice / 100,
      },
    }
  }

  products.forEach((product) => {
    const { pattern, fabric, type, colors, parent_category } = product

    const typeValue = kebabCase(type?.originalLabel)
    // Color filter
    colors?.forEach((color) => {
      if (color && !filter.color.some((i) => i.value === color.value)) {
        filter.color.push({
          value: color.value,
          label: capitalize(color.label),
        })
      }
    })
    // Category filter
    if (
      parent_category?.label &&
      !filter.category.some((i) => i.label === parent_category?.label) &&
      parent_category?.metadata?.category_type !== "landing-page"
    ) {
      filter.category.push(parent_category)
    }

    // Product filter
    if (typeValue && !filter.product.some((i) => i.value === typeValue)) {
      filter.product.push({
        value: typeValue,
        label: getTypePlural(type.originalLabel),
      })
    }

    // Pattern filter
    if (
      pattern?.label &&
      !filter.pattern.some((i) => i.value === pattern?.value)
    ) {
      filter.pattern.push({
        value: pattern.value,
        label: capitalize(pattern.label),
      })
    }

    // Fabric filter
    if (
      parent_category?.value !== "limited-editions" &&
      fabric?.value !== "flannel-loungewear" &&
      fabric?.label &&
      !filter.fabric.some((i) => i.label === fabric?.label)
    ) {
      filter.fabric.push(fabric)
    }
  })

  // Sort filter data
  filter.color = filter.color.sort((a, b) => a.label?.localeCompare(b.label))
  filter.pattern = filter.pattern.sort((a, b) =>
    a.label?.localeCompare(b.label)
  )
  filter.fabric = filter.fabric.sort((a, b) => a.label?.localeCompare(b.label))
  filter.category = filter.category.sort((a, b) =>
    a.label?.localeCompare(b.label)
  )
  filter.product = filter.product.sort((a, b) =>
    a.label?.localeCompare(b.label)
  )
  return filter
}

const getSelectedFiltersNumber = (selectedFilters: SelectedFilters): number => {
  const number = Object.values(selectedFilters).filter(
    (values) => Array.isArray(values) && values.length > 0
  ).length

  if (
    selectedFilters["price_min"] !== null ||
    selectedFilters["price_max"] !== null
  ) {
    return number + 1
  }

  return number
}

const SORT_OPTIONS = [
  {
    label: "Latest arrivals",
    value: "latest-arrivals",
  },
  {
    label: "Price (Low to high)",
    value: "price-low-to-high",
  },
  {
    label: "Price (High to low)",
    value: "price-high-to-low",
  },
]

const AllProductsTemplate = ({
  pageContext,
}: PageProps<object, AllProductsTemplateProps>) => {
  const { products, seo } = pageContext
  const categoryGridRef = React.useRef(null)
  const filterNavigationAnchorRef = React.useRef(null)
  const filterButtonTriggerPointRef = React.useRef(null)
  const filterFloatingButtonRef = React.useRef(null)
  const quickFiltersRef = React.useRef(null)
  const { cart } = useStore()
  const location = useLocation()
  const { properties } = useAccountContext()
  const windowSize = useWindowSize()

  const searchParams = new URLSearchParams(location.search)
  const [selectedFilters, setSelectedFilters] =
    React.useState<SelectedFilters>("")
  const [selectedSort, setSelectedSort] = React.useState<string>()

  React.useEffect(() => {
    setSelectedFilters({
      category: getFilterParam("category", searchParams),
      color: getFilterParam("color", searchParams),
      fabric: getFilterParam("fabric", searchParams),
      pattern: getFilterParam("pattern", searchParams),
      price_min: searchParams.get("price_min"),
      price_max: searchParams.get("price_max"),
      product: getFilterParam("product", searchParams),
    })
    setSelectedSort(searchParams.get("sort"))
  }, [location.search])

  // Get price range
  const { data: prices, refetch: refetchPrices } = useProductsPriceRange(
    "",
    cart?.region_id,
    cart?.region?.currency_code
  )

  const { data: bestsellers } = useProductsBestsellers(cart?.region_id, {
    enabled: Boolean(cart?.region_id),
    keepPreviousData: true,
  })

  // Exclude Gift card and internal archive products for logged out user
  const purchasableProducts = React.useMemo(() => {
    const purchasable = products?.filter(
      (product) =>
        isPurchasable(product?.sku, properties) && product.sku !== "T-GC"
    )
    if (!prices) {
      return purchasable
    }
    return purchasable.map((p) => {
      return {
        ...p,
        priceMin: prices?.find((price) => price.id === p.id)?.min,
        priceMax: prices?.find((price) => price.id === p.id)?.max,
      }
    })
  }, [products, properties, prices])

  const sortProducts = (products: Product[]) => {
    // Sort by bestsellers and then by product type
    if (!selectedSort) {
      const featured = products?.filter((p) => p.metadata?.all_products_rank)
      const featuredSorted = featured.toSorted((a, b) => {
        return (
          parseInt(a.metadata?.all_products_rank) -
          parseInt(b.metadata?.all_products_rank)
        )
      })

      const rest = products?.filter((p) => !p.metadata?.all_products_rank)
      let sorted = rest

      if (bestsellers?.length > 0) {
        sorted = rest.toSorted((a, b) => {
          const aIsBestseller = bestsellers?.includes(a?.id)
          const bIsBestseller = bestsellers?.includes(b?.id)

          if (aIsBestseller && bIsBestseller) {
            return bestsellers.indexOf(a.id) - bestsellers?.indexOf(b.id)
          } else if (aIsBestseller) {
            return -1
          } else if (bIsBestseller) {
            return 1
          } else {
            return 0
          }
        })
      } else {
        sorted = rest.toSorted((a, b) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        })
      }

      // Group products by type
      const productsByType = sorted.reduce((acc, product) => {
        const { type } = product
        acc[type.value] = [...(acc[type.value] || []), product]
        return acc
      }, {})

      // Create alternating pattern, where products are sorted by type, but alternating between types
      let sortedProducts = []
      const types = Object.keys(productsByType)
      let maxLength = Math.max(
        ...types.map((type) => productsByType[type].length)
      )

      for (let i = 0; i < maxLength; i++) {
        types.forEach((type) => {
          if (productsByType[type][i]) {
            sortedProducts.push(productsByType[type][i])
          }
        })
      }

      return featuredSorted.concat(sortedProducts)
    }

    if (selectedSort === "latest-arrivals") {
      return products.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
    }

    if (selectedSort === "price-low-to-high") {
      return products.sort((a, b) => {
        return a.priceMin - b.priceMin
      })
    }

    if (selectedSort === "price-high-to-low") {
      return products.sort((a, b) => {
        return b.priceMax - a.priceMax
      })
    }

    return products
  }
  // Set grid data
  const gridData = () => {
    let data: Data[] = []
    if (!selectedFilters.category) {
      return []
    }

    let filteredProducts = filterProducts(purchasableProducts, selectedFilters)

    filteredProducts = sortProducts(filteredProducts)
    data = filteredProducts?.map((product, i) => {
      return {
        ...product,
        variant: "product",
      }
    })

    return data
  }

  const getRows = (element: HTMLElement[]) => {
    const tolerance = 1
    const rows = new Array()

    // Group elements by their bottom position
    element.forEach((element) => {
      // Get the bottom position of the element
      const bottom = element?.offsetTop + element.offsetHeight
      // Find a row with the same bottom position
      const sameRowArray = rows.find((row) => {
        const rowBottom = row[0]?.offsetTop + row[0].offsetHeight
        return Math.floor(Math.abs(rowBottom - bottom)) <= tolerance
      })

      // If a row with the same bottom position exists and element is not a
      // widget, add the element to that row, otherwise create a new row
      if (element.dataset.textual !== "true") {
        if (rows.length > 0 && Boolean(sameRowArray)) {
          sameRowArray.push(element)
        } else {
          rows.push([element])
        }
      }
    })

    return rows
  }

  const data = React.useMemo(
    () => gridData(),
    [purchasableProducts, selectedFilters, bestsellers]
  )
  const filterData = React.useMemo(
    () => getFilterData(purchasableProducts),
    [purchasableProducts, cart?.region]
  )

  const numOfProducts = data?.filter(
    (item) => item.variant === "product"
  )?.length

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = React.useState(false)
  const [locked, setLocked] = useLockedBody(false)
  const [lockedMobile, setLockedMobile] = useLockedBodyMobile(false)
  const [accordionValues, setAccordionValues] = React.useState<string[]>([])

  const getFirstPageLength = (data: Data[], defaultLength: number): number => {
    let visualLength = 0

    data.slice(0, defaultLength).forEach((item) => {
      if (item.variant === "product") {
        visualLength += 1
      }
    })

    if (visualLength % 4 !== 0) {
      return defaultLength + (4 - (visualLength % 4))
    }

    return defaultLength
  }

  const getVisibleLength = (
    data: Data[],
    page: number,
    totalPages: number,
    contentPerPage: number
  ): number => {
    if (page === totalPages) {
      return data.length
    }

    return (
      getFirstPageLength(data, contentPerPage) + (page - 1) * contentPerPage
    )
  }

  const currentPage = searchParams.get("page")
    ? parseInt(searchParams.get("page"))
    : 1

  const CONTENT_PER_PAGE = 32
  const { nextPage, page, totalPages } = usePagination({
    contentPerPage: CONTENT_PER_PAGE,
    count: data.length,
    currentPage: currentPage,
  })
  const visibleLength = getVisibleLength(
    data,
    page,
    totalPages,
    CONTENT_PER_PAGE
  )

  const handlePageChange = (page: number) => {
    const pageStr = page.toString()
    const queryParams = new URLSearchParams(location.search)
    queryParams.set("page", pageStr)

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      state: { disableScrollToTop: true },
    })
  }

  React.useEffect(() => {
    const handleScroll = () => {
      if (!filterButtonTriggerPointRef.current) return
      if (
        document.documentElement.scrollTop + window.innerHeight >
        filterButtonTriggerPointRef.current?.offsetTop
      ) {
        filterFloatingButtonRef.current.style.opacity = 0
        filterFloatingButtonRef.current.style.visibility = "hidden"
      } else {
        filterFloatingButtonRef.current.style.opacity = 1
        filterFloatingButtonRef.current.style.visibility = "visible"
      }

      if (
        document.documentElement.scrollTop >
        filterNavigationAnchorRef.current?.offsetTop
      ) {
        quickFiltersRef.current.style.display = "flex"
      } else {
        quickFiltersRef.current.style.display = "none"
      }
    }

    handleScroll()

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  React.useEffect(() => {
    if (cart?.region_id) {
      refetchPrices()
    }
  }, [cart?.region_id])

  React.useEffect(() => {
    // Get elements grouped by their bottom position
    if (!categoryGridRef.current) return
    const rows = getRows(Array.from(categoryGridRef.current.children))

    const handleResize = () => {
      // Set the height of each image element to the highest footer height in the row
      rows.forEach((row) => {
        const highestFooterHeight = Math.max(
          ...row.map((element: HTMLElement) => {
            const footerElement = element.querySelector(
              "[data-footer]"
            ) as HTMLElement
            if (Boolean(footerElement) === false) return
            return footerElement.offsetHeight
          })
        )

        row.forEach((item: HTMLElement) => {
          const imageElement = item.querySelector("[data-image]") as HTMLElement
          if (Boolean(imageElement)) {
            imageElement.style.height = `calc(100% - ${
              highestFooterHeight + "px"
            })`
          }
        })
      })
    }

    handleResize()

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [data, page, prices])

  const canonicalUrl = searchParams.get("page")
    ? `${seo.canonicalUrl}?page=${searchParams.get("page")}`
    : seo.canonicalUrl

  return (
    <Layout almostWhite>
      <SEO
        title="All products"
        metaImage={seo.metaImageUrl && seo.metaImageUrl}
        description={seo.description}
        canonicalUrl={canonicalUrl}
        breadcrumbsList={seo.breadcrumbsList}
      />

      <Box sx={{ paddingBlockStart: [3, 21], paddingBlockEnd: [8, 18] }}>
        <Box sx={{ paddingInline: 4, marginBlockEnd: 3 }}>
          <Heading as="h1" sx={{ fontSize: "xl" }}>
            All products
          </Heading>
        </Box>
        {filterData?.category?.length > 1 ? (
          <Box
            sx={{
              overflowX: ["scroll", "unset"],
              borderBlock: ["1px solid", "none"],
              borderBlockColor: ["grayscale.300", "transparent"],
              scrollbarWidth: "none",
              paddingBlock: [5, 0],
              marginInline: [0, 4],
              marginBlockStart: 6,
              marginBlockEnd: 4,
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Flex sx={{ fontSize: "sm", gap: 6 }}>
              <Box sx={{ flexShrink: 0, paddingInlineStart: [4, 0] }}>
                <Link
                  sx={{
                    borderBlockEnd:
                      selectedFilters.category?.length === 0 ? "1px solid" : 0,
                  }}
                  onClick={() => navigate(`${location.pathname}`)}
                >
                  All
                </Link>
              </Box>
              {filterData.category?.map((type) => (
                <Box key={type.value} sx={{ flexShrink: 0 }}>
                  <Link
                    sx={{
                      borderBlockEnd:
                        selectedFilters.category?.length === 1 &&
                        selectedFilters.category?.[0] === type.value
                          ? "1px solid"
                          : 0,
                    }}
                    onClick={() =>
                      navigate(`${location.pathname}?category=${type.value}`)
                    }
                  >
                    {type.label}
                  </Link>
                </Box>
              ))}
            </Flex>
          </Box>
        ) : null}
        <Box ref={filterNavigationAnchorRef} />
        <Flex
          sx={{
            height: 13,
            backgroundColor: "grayscale.100",
            position: ["initial", "sticky"],
            top: 0,
            zIndex: "categoryBar",
            fontSize: "sm",
            alignItems: "center",
            justifyContent: "space-between",
            paddingInline: 4,
            marginBlockEnd: 3,
          }}
        >
          <Flex sx={{ gap: 3 }}>
            <Text sx={{ color: "grayscale.600" }}>
              {numOfProducts} products
            </Text>
          </Flex>
          <Box sx={{ display: ["none", "flex"], gap: 5 }}>
            <Button
              variant="link"
              iconLeft={
                <Icon name="sliders" size="4" sx={{ marginBlockEnd: 1 }} />
              }
              onClick={() => {
                setIsFilterDrawerOpen(true)
                setLocked(true)
              }}
            >
              Filter
              {getSelectedFiltersNumber(selectedFilters) > 0 &&
                " (" + getSelectedFiltersNumber(selectedFilters) + ")"}
            </Button>
            <Box
              ref={quickFiltersRef}
              sx={{ display: ["none", "flex"], gap: 5 }}
            >
              {Object.keys(filterData)
                .filter(
                  (value) =>
                    !(
                      Array.isArray(filterData[value]) &&
                      filterData[value].length < 2
                    )
                )
                .map((filterType) => (
                  <Button
                    key={filterType}
                    variant="link"
                    onClick={() => {
                      setAccordionValues([filterType])
                      setIsFilterDrawerOpen(true)
                    }}
                  >
                    {capitalize(filterType)}{" "}
                    {selectedFilters?.[filterType]?.length > 0
                      ? `(${selectedFilters?.[filterType]?.length})`
                      : filterType === "price" &&
                        selectedFilters["price_min"] !== null &&
                        selectedFilters["price_max"] !== null
                      ? "(1)"
                      : null}
                  </Button>
                ))}
            </Box>
          </Box>
        </Flex>
        {bestsellers ? (
          <UiCategoryGrid ref={categoryGridRef}>
            {data?.length > 0 &&
              data.slice(0, visibleLength).map((item) => {
                return (
                  <VisualItem
                    key={item.id}
                    title={item.title}
                    handle={item.handle}
                    image={item.image}
                    imageOnHover={item.imageOnHover}
                    priceMin={item.priceMin}
                    priceMax={item.priceMax}
                  />
                )
              })}
          </UiCategoryGrid>
        ) : (
          <Lottie
            options={{
              autoplay: true,
              loop: true,
              animationData: require("../../assets/lottie/loader.json"),
            }}
            style={{
              width: 60,
              height: 60,
            }}
          />
        )}
        <Box
          ref={filterButtonTriggerPointRef}
          sx={{
            width: 53,
            maxWidth: "100%",
            marginInline: "auto",
            textAlign: "center",
            marginBlockStart: [13, 18],
          }}
        >
          {bestsellers && (
            <>
              <Paragraph
                sx={{ fontSize: "sm", marginBlockEnd: data.length > 0 && 6 }}
              >
                {data.length > 0 ? (
                  <>
                    Showing {visibleLength} of {numOfProducts} products
                  </>
                ) : (
                  <>No results</>
                )}
              </Paragraph>

              {data.length > 0 && (
                <UiProgress value={(visibleLength / data.length) * 100} />
              )}
            </>
          )}
          {page !== totalPages && data.length > 0 && bestsellers && (
            <Button
              variant="ghost"
              href={`${location.pathname}?page=${page + 1}`}
              sx={{ marginBlockStart: 6 }}
              onClick={(e) => {
                e.preventDefault()
                nextPage()
                handlePageChange(page + 1)
              }}
              disabled={page === totalPages}
              isVisuallyDisabled={page === totalPages}
            >
              Show more
            </Button>
          )}
          {data.length < 1 && (
            <Button
              variant="ghost"
              sx={{ marginBlockStart: 6 }}
              onClick={() => {
                navigate(location.pathname)
              }}
            >
              Clear all filters
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ display: ["none", "block"] }}>
        <Drawer
          isOpened={isFilterDrawerOpen}
          size="xl"
          onCloseClick={() => {
            setIsFilterDrawerOpen(false)
            setLocked(false)
          }}
          onBackdropClick={() => {
            setIsFilterDrawerOpen(false)
            setLocked(false)
          }}
        >
          <FilterDrawerContent
            filterData={filterData}
            numOfProducts={numOfProducts}
            accordionValues={accordionValues}
            setAccordionValues={setAccordionValues}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
            selectedFilters={selectedFilters}
            setIsFilterDrawerOpen={setIsFilterDrawerOpen}
            setLocked={setLocked}
            products={purchasableProducts}
            isUSA={cart?.region?.name === "United States"}
            filterNavigationAnchorRef={filterNavigationAnchorRef}
            isMobile={false}
          />
        </Drawer>
      </Box>
      <Box sx={{ display: ["flex", "none"] }}>
        <Drawer
          isOpened={isFilterDrawerOpen}
          position="bottom"
          size="xl"
          onCloseClick={() => {
            setIsFilterDrawerOpen(false)
            setLockedMobile(false)
          }}
          onBackdropClick={() => {
            setIsFilterDrawerOpen(false)
            setLockedMobile(false)
          }}
          onSwipeRight={() => {
            setIsFilterDrawerOpen(false)
            setLockedMobile(false)
          }}
        >
          <FilterDrawerContent
            filterData={filterData}
            numOfProducts={numOfProducts}
            accordionValues={accordionValues}
            setAccordionValues={setAccordionValues}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
            selectedFilters={selectedFilters}
            setIsFilterDrawerOpen={setIsFilterDrawerOpen}
            setLockedMobile={setLockedMobile}
            products={purchasableProducts}
            isUSA={cart?.region?.name === "United States"}
            filterNavigationAnchorRef={filterNavigationAnchorRef}
            isMobile={true}
          />
        </Drawer>
      </Box>
      <Box
        ref={filterFloatingButtonRef}
        sx={{
          transition: "opacity 0.2s, visibility 0.2s",
          display: ["block", "none"],
        }}
      >
        <Button
          variant="secondary"
          iconLeft={<Icon name="sliders" size="4" sx={{ marginBlockEnd: 1 }} />}
          onClick={() => {
            setIsFilterDrawerOpen(true)
            setLockedMobile(true)
          }}
          sx={{
            position: "fixed",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Filter
          {getSelectedFiltersNumber(selectedFilters) > 0 &&
            " (" + getSelectedFiltersNumber(selectedFilters) + ")"}
        </Button>
      </Box>
    </Layout>
  )
}

export const UiCategoryGrid: React.FC<GridProps> = React.forwardRef(
  ({ ...rest }, ref) => (
    <Grid
      {...rest}
      ref={ref}
      sx={{
        gridAutoFlow: "dense",
        gridTemplateColumns: ["repeat(2, 1fr)", "repeat(4, 1fr)"],
        gridTemplateRows: "1fr",
        columnGap: 4,
        rowGap: 8,
        paddingInline: 4,
      }}
    />
  )
)

type VisualItemProps = LinkProps & {
  handle: string
  image: IGatsbyImageData
  imageOnHover?: IGatsbyImageData
  title: string
  size?: "default" | "large"
  priceMin?: number
  priceMax?: number
  selectedSize?: string
}

export const VisualItem: React.FC<VisualItemProps> = ({
  handle,
  image,
  imageOnHover,
  title,
  size,
  priceMin,
  priceMax,
  selectedSize,
  ...rest
}) => {
  const { cart } = useStore()
  return (
    <Box
      sx={{
        "> a": {
          color: "primary",
          textDecoration: "none",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100%",
          gridColumn: size === "large" && "1 / span 2",
          gridRow: size === "large" && "span 2",
          "&:hover .hover-image": {
            opacity: [0, 1],
            visibility: ["hidden", "visible"],
          },
        },
      }}
    >
      <GatsbyLink
        to={handle}
        state={{ size: selectedSize, scrollTo: { x: 0, y: 0 } }}
      >
        <Box data-image sx={{ position: "relative", height: "100%" }}>
          <Picture
            sources={image.images.sources}
            sx={{ height: "100%" }}
            imageProps={{
              width: image.width,
              height: image.height,
              sizes: image.images.fallback.sizes,
              src: getBunnyImageSrc(image.images.fallback.src),
              srcSet: getBunnyImageSrc(image.images.fallback.srcSet),
              alt: title,
              decoding: "sync",
              loading: "eager",
            }}
          />
          {Boolean(imageOnHover) && (
            <Picture
              className="hover-image"
              sources={imageOnHover.images.sources}
              sx={{
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                visibility: "hidden",
              }}
              imageProps={{
                width: imageOnHover.width,
                height: imageOnHover.height,
                sizes: imageOnHover.images.fallback.sizes,
                src: getBunnyImageSrc(imageOnHover.images.fallback.src),
                srcSet: getBunnyImageSrc(imageOnHover.images.fallback.srcSet),
                alt: title,
              }}
            />
          )}
        </Box>
        <Box data-footer>
          {Boolean(title) && (
            <Paragraph
              sx={{
                fontSize: "sm",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                paddingBlockStart: 2,
              }}
            >
              {title}
            </Paragraph>
          )}
          {Boolean(priceMin) && Boolean(priceMax) && (
            <Paragraph sx={{ fontSize: "sm", marginBlockStart: 1 }}>
              {priceMin === priceMax ? (
                formatMoneyAmount({
                  amount: priceMin,
                  currencyCode: cart?.region?.currency_code,
                })
              ) : (
                <>
                  {formatMoneyAmount({
                    amount: priceMin,
                    currencyCode: cart?.region?.currency_code,
                  })}{" "}
                  -{" "}
                  {formatMoneyAmount({
                    amount: priceMax,
                    currencyCode: cart?.region?.currency_code,
                  })}
                </>
              )}
            </Paragraph>
          )}
        </Box>
      </GatsbyLink>
    </Box>
  )
}

export const ShopstoryModule = ({ shopstoryContent }) => {
  return (
    <TeklaShopstoryProvider>
      <Shopstory {...shopstoryContent} />
    </TeklaShopstoryProvider>
  )
}

export const FilterDrawerContent = ({
  filterData,
  numOfProducts,
  accordionValues,
  setAccordionValues,
  selectedSort,
  setSelectedSort,
  selectedFilters,
  setIsFilterDrawerOpen,
  setLocked,
  setLockedMobile,
  products,
  filterNavigationAnchorRef,
  isMobile,
  isSearchPage = false,
}) => {
  const [selected, setSelected] =
    React.useState<SelectedFilters>(selectedFilters)
  const { cart } = useStore()

  const getFilterValueLabel = (filterType: string, value: string) => {
    const filter = filterData[filterType].find((item) => item.value === value)
    return filter?.label
  }

  const prevFilter = React.useRef()

  // Handle filter changes
  const handleFilterChange = (filterType: string, value: string | []) => {
    if (filterType === "price") {
      setSelected((prevFilters) => {
        return {
          ...prevFilters,
          price_min: value[0],
          price_max: value[1],
        }
      })
    } else {
      setSelected((prevFilters) => {
        // If value is already in the array, remove it, otherwise add it
        const values = prevFilters[filterType]
        if (values?.includes(value)) {
          values.splice(values.indexOf(value), 1)
        } else {
          values.push(value)
        }

        return {
          ...prevFilters,
          [filterType]: values,
        }
      })
    }
  }

  // Handle view products
  const handleViewProducts = () => {
    // Ignore empty values
    const newFiltersEntries = Object.entries(selected).filter(
      ([key, value]) =>
        (key === "price_min" && value && value >= 0) ||
        (key === "price_max" && value && value >= 0) ||
        value?.length > 0
    )

    if (isSearchPage) {
      const params = new URLSearchParams(location.search)
      const searchParam = params.get("q")

      if (searchParam) {
        newFiltersEntries.push(["q", searchParam])
      }
    }

    // Add sort parameter
    if (selectedSort) {
      newFiltersEntries.push(["sort", [selectedSort]])
    }

    // Update URL with new query parameters
    const queryParams = new URLSearchParams(newFiltersEntries)

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      state: {
        scrollTo: {
          x: 0,
          y: filterNavigationAnchorRef.current?.offsetTop - 52,
        },
      },
    })
  }

  React.useEffect(() => {
    setSelected(selectedFilters)
  }, [selectedFilters])

  const filter = React.useMemo(() => {
    const filteredProducts = filterProducts(products, selected)
    const keepProductFilter = selected.product?.length > 0

    const filterData = getFilterData(
      filteredProducts,
      keepProductFilter ? prevFilter.current?.product : []
    )
    return {
      ...filterData,
      numOfFilteredProducts: filteredProducts?.length,
    }
  }, [products, selected])

  React.useEffect(() => {
    prevFilter.current = filter
  }, [filter])

  return (
    <>
      <Box sx={{ marginBlockEnd: 10 }}>
        <Paragraph sx={{ fontSize: "xl" }}>Filter & sort</Paragraph>
        <Paragraph sx={{ color: "grayscale.600", fontSize: "xs" }}>
          {numOfProducts} products
        </Paragraph>
      </Box>
      <Accordion.Root
        type="multiple"
        value={accordionValues}
        onValueChange={setAccordionValues}
      >
        <Accordion.Item asChild value="sort">
          <UiAccordionItem>
            <Accordion.Header asChild>
              <UiAccordionHeader>
                <Accordion.Trigger asChild>
                  <UiAccordionTrigger>Sort</UiAccordionTrigger>
                </Accordion.Trigger>
              </UiAccordionHeader>
            </Accordion.Header>
            <Accordion.Content asChild>
              <UiAccordionContent>
                <RadioGroup.Root>
                  <Grid gap={5} columns={["repeat(2, 1fr)"]}>
                    {SORT_OPTIONS.map((option) => (
                      <UiRadioGroupField key={option.value}>
                        <RadioGroup.Item
                          value={option.value}
                          id={option.value}
                          asChild
                          checked={selectedSort === option.value}
                          onClick={(e) => {
                            setSelectedSort((prevState) =>
                              prevState === e.target.value ? "" : e.target.value
                            )
                          }}
                        >
                          <UiRadioGroupItem />
                        </RadioGroup.Item>
                        <UiRadioGroupLabel htmlFor={option.value}>
                          {option.label}
                        </UiRadioGroupLabel>
                      </UiRadioGroupField>
                    ))}
                  </Grid>
                </RadioGroup.Root>
              </UiAccordionContent>
            </Accordion.Content>
          </UiAccordionItem>
        </Accordion.Item>
        {Object.keys(filterData)
          .filter(
            (value) =>
              !(
                (Array.isArray(filterData[value]) &&
                  filterData[value].length < 2) ||
                (value === "product" && selected.category?.length < 1)
              )
          )
          .map((value) => (
            <React.Fragment key={value}>
              <Accordion.Item asChild value={value}>
                <UiAccordionItem>
                  <Accordion.Header asChild>
                    <UiAccordionHeader>
                      <Accordion.Trigger asChild>
                        <UiAccordionTrigger
                          sideContent={selected?.[value]?.length}
                        >
                          {capitalize(value)}
                        </UiAccordionTrigger>
                      </Accordion.Trigger>
                    </UiAccordionHeader>
                  </Accordion.Header>
                  <Accordion.Content asChild>
                    <UiAccordionContent>
                      {Array.isArray(filterData[value]) ? (
                        <Grid gap={5} columns={["repeat(2, 1fr)"]}>
                          {filterData[value].map((item) => {
                            if (
                              value === "product" &&
                              selected.category?.length < 1 &&
                              selected.product?.length < 1
                            ) {
                              return null
                            }

                            if (
                              value === "product" &&
                              !filter?.[value]?.some(
                                (i) => i.value === item.value
                              )
                            ) {
                              return null
                            }

                            // Filter value is disabled if filter type (e.g. color, pattern) is not already selected and if its value is not present in new filter information
                            const isDisabled =
                              !isSearchPage &&
                              selected[value]?.length < 1 &&
                              !filter?.[value]?.some(
                                (i) => i.value === item.value
                              )
                            return (
                              <Flex key={item.value}>
                                <Checkbox.Root
                                  id={
                                    isMobile
                                      ? item.value + "-mobile"
                                      : item.value + "-desktop"
                                  }
                                  asChild
                                  checked={
                                    selected[value]?.includes(item.value) ||
                                    false
                                  }
                                  onClick={() => {
                                    if (!isDisabled) {
                                      handleFilterChange(value, item.value)
                                    }
                                  }}
                                  disabled={isDisabled}
                                >
                                  <UiRadixCheckbox>
                                    <Checkbox.Indicator forceMount asChild>
                                      <UiRadixCheckboxIcon name="checkmark" />
                                    </Checkbox.Indicator>
                                  </UiRadixCheckbox>
                                </Checkbox.Root>
                                <UiRadixCheckboxLabel
                                  htmlFor={
                                    isMobile
                                      ? item.value + "-mobile"
                                      : item.value + "-desktop"
                                  }
                                >
                                  {isDisabled ? (
                                    <Text
                                      sx={{
                                        color: "grayscale.400",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {item.label}
                                    </Text>
                                  ) : (
                                    item.label
                                  )}
                                </UiRadixCheckboxLabel>
                              </Flex>
                            )
                          })}
                        </Grid>
                      ) : value === "price" ? (
                        <PriceRangeSlider
                          min={filterData?.price?.min}
                          max={filterData?.price?.max}
                          currencyCode={cart?.region?.currency_code}
                          handleValueChange={(value) =>
                            handleFilterChange("price", value)
                          }
                          defaultValues={[
                            selected.price_min || filterData?.price?.min,
                            selected.price_max || filterData?.price?.max,
                          ]}
                        />
                      ) : null}
                    </UiAccordionContent>
                  </Accordion.Content>
                </UiAccordionItem>
              </Accordion.Item>
            </React.Fragment>
          ))}
      </Accordion.Root>
      <Flex
        sx={{
          flexDirection: "column",
          gap: 4,
          width: "100%",
          maxWidth: 126,
          backgroundColor: "grayscale.white",
          position: "sticky",
          bottom: 0,
          paddingBlock: 7,
          marginBlockEnd: -10,
        }}
      >
        <Flex
          sx={{
            flexWrap: "wrap",
            alignItems: "center",
            gap: 3,
          }}
        >
          {Object.entries(selected)
            .filter(([filterType, values]) => Array.isArray(values))
            .map(([filterType, values]) => {
              return values?.map((value) => (
                <Flex
                  key={value}
                  sx={{
                    height: 8,
                    alignItems: "center",
                    gap: 2,
                    border: "1px solid",
                    borderColor: "grayscale.600",
                    paddingInline: 2,
                  }}
                >
                  <Text>{getFilterValueLabel(filterType, value)}</Text>
                  <Button
                    variant="link"
                    onClick={() => handleFilterChange(filterType, value)}
                  >
                    <Icon name="x" size={3} />
                  </Button>
                </Flex>
              ))
            })}
          {Object.values(selected).some((value) => Boolean(value?.length)) && (
            <Box>
              <Button
                variant="link"
                onClick={() =>
                  setSelected({
                    category: [],
                    color: [],
                    fabric: [],
                    pattern: [],
                    product: [],
                    size: [],
                    price_min: undefined,
                    price_max: undefined,
                  })
                }
              >
                Clear all
              </Button>
            </Box>
          )}
        </Flex>
        <Button
          variant="primary"
          onClick={() => {
            handleViewProducts()
            setIsFilterDrawerOpen(false)

            if (setLockedMobile !== undefined) {
              setLockedMobile(false)
            } else {
              setLocked(false)
            }
          }}
          sx={{ width: "100%" }}
          disabled={filter.numOfFilteredProducts === 0 && !isSearchPage}
          isVisuallyDisabled={
            filter.numOfFilteredProducts === 0 && !isSearchPage
          }
        >
          View {isSearchPage ? "" : filter.numOfFilteredProducts} products
        </Button>
      </Flex>
    </>
  )
}

type PriceRangeSliderProps = BoxProps & {
  min: number
  max: number
  defaultValues: number[]
  currencyCode: string
  handleValueChange: (value: number[]) => void
}

const PriceRangeSlider: React.FC<PriceRangeSliderProps> = React.forwardRef(
  (
    { min, max, defaultValues, currencyCode, handleValueChange, ...rest },
    ref
  ) => {
    const [priceRangeValues, setPriceRangeValues] =
      React.useState<number[]>(defaultValues)

    const handleInputOnChange = (event, valueIndex) => {
      const value = Number(event.target.value)

      if (value < event.target.min || value > event.target.max) {
        //return
      }

      if (valueIndex === 0) {
        setPriceRangeValues((prevValue) => [value, prevValue[1]])
        handleValueChange([value, priceRangeValues[1]])
        return
      }

      if (valueIndex === 1) {
        setPriceRangeValues((prevValue) => [prevValue[0], value])
        handleValueChange([priceRangeValues[0], value])
        return
      }
    }

    React.useEffect(() => {
      setPriceRangeValues(defaultValues)
    }, [defaultValues])

    return (
      <Box {...rest} ref={ref}>
        <Slider.Root
          value={priceRangeValues}
          defaultValue={priceRangeValues}
          minStepsBetweenThumbs={1}
          min={min}
          max={max}
          step={1}
          onValueChange={(value) => {
            setPriceRangeValues(value)
          }}
          onValueCommit={(value) => handleValueChange(value)}
          asChild
        >
          <UiSlider sx={{ marginBlockEnd: 6 }}>
            <Slider.Track asChild>
              <UiSliderTrack>
                <Slider.Range asChild>
                  <UiSliderRange />
                </Slider.Range>
              </UiSliderTrack>
            </Slider.Track>
            <Slider.Thumb asChild>
              <UiSliderThumb />
            </Slider.Thumb>
            <Slider.Thumb asChild>
              <UiSliderThumb />
            </Slider.Thumb>
          </UiSlider>
        </Slider.Root>
        <Flex sx={{ alignItems: "center" }}>
          <Box sx={{ position: "relative", flex: 1 }}>
            <Input
              type="number"
              value={priceRangeValues?.[0]}
              min={min}
              max={max}
              onChange={(event) => handleInputOnChange(event, 0)}
              sx={{
                textAlign: "center",
                borderRadius: 0,
                borderColor: "grayscale.200",
                "&:focus": {
                  outline: "none",
                },
                "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                  appearance: "none",
                },
              }}
            />
            <Text
              sx={{
                position: "absolute",
                top: "50%",
                right: 3,
                transform: "translateY(-50%)",
              }}
            >
              {currencyCode}
            </Text>
          </Box>
          <Box sx={{ width: 16, textAlign: "center", flexShrink: 0 }} />
          <Box sx={{ position: "relative", flex: 1 }}>
            <Input
              type="number"
              value={priceRangeValues?.[1]}
              min={min}
              max={max}
              onChange={(event) => handleInputOnChange(event, 1)}
              sx={{
                textAlign: "center",
                borderRadius: 0,
                borderColor: "grayscale.200",
                "&:focus": {
                  outline: "none",
                },
                "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                  appearance: "none",
                },
              }}
            />
            <Text
              sx={{
                position: "absolute",
                top: "50%",
                right: 3,
                transform: "translateY(-50%)",
              }}
            >
              {currencyCode}
            </Text>
          </Box>
        </Flex>
      </Box>
    )
  }
)

PriceRangeSlider.displayName = "PriceRangeSlider"

export default AllProductsTemplate
